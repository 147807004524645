import React from "react";
import styles from "./RadioBtn.module.css";

const RadioBtn = ({ title, name, id, checked, handleChange, value }) => {
  return (
    <div className={`${styles.radioWrapper}`}>
      <label htmlFor={id} className={`${styles.radioLable}`}>
        <input
          type="radio"
          id={id}
          name={name}
          value={value}
          checked={checked}
          onChange={(e) => handleChange(e)}
          className={`${styles.radioCus}`}
        />
        <span>{title}</span>
      </label>
    </div>
  );
};

export default RadioBtn;
