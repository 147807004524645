import React, { useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import InputFields from "../../../Components/Common/InputField/InputFields";
import classes from "./FormSettingModal.module.css";

const FormSettingModal = (props) => {
  const {
    setIsShowPopup,
    role,
    handleInputChange,
    handleEmployeerFormSetting,
    newRole,
    setRole,
    allCompanies,
    isEdit,
    editNewRole,
    setisEdit,
    handleChangeSite,
    handleChangeCompanies,
    getSitesFromCompanies,
  } = props;
  const [dropDownDisabled, setDropDownDisabled] = useState(false);
  return (
    <Modal
      show={true}
      onHide={() => {
        setIsShowPopup(false);
        setisEdit(false);
        setRole("");
        // resetFormFields();
      }}
      size="md"
    >
      <Modal.Header className="modal-user" closeButton></Modal.Header>
      <Form onSubmit={handleEmployeerFormSetting}>
        <Modal.Body className="p-4">
          <Modal.Title className="text-center">
            Employer Form Setting
          </Modal.Title>
          <div className="row">
            <div className="col-sm-12 mb-2">
              <InputFields
                type="text"
                inputStyle="modalInput"
                labelStyle="text-capitalize"
                label="Employer"
                placeholder="Employer"
                name="role"
                cssClass="pe-4"
                value={role}
                handleChange={handleInputChange}
              />
            </div>
            {isEdit ? (
              ""
            ) : (
              <div>
                <div className="col-lg-12 mb-2">
                  <Form.Group className={`${classes.formGroupWrapper}`}>
                    <label
                      className={`${classes.selectOptionLabel} form-label`}
                    >
                      Companies
                    </label>
                    <div className={`${classes.customSelectWrapper}`}>
                      <select
                        className={`${classes.customSelect} form-control`}
                        value={newRole.companyId}
                        onChange={handleChangeCompanies}
                      >
                        <option value="" disabled>
                          Select Company
                        </option>
                        {allCompanies &&
                          allCompanies.map((company) => (
                            <option value={company.companyId} key={company.id}>
                              {company.companyname}
                            </option>
                          ))}
                      </select>
                    </div>
                  </Form.Group>
                </div>
                <div className="col-lg-12">
                  <Form.Group className={`${classes.formGroupWrapper}`}>
                    <label
                      className={`${classes.selectOptionLabel} form-label`}
                    >
                      Sites
                    </label>
                    <div className={`${classes.customSelectWrapper}`}>
                      <select
                        disabled={!newRole.companyId}
                        className={`${classes.customSelect} form-control`}
                        value={newRole.siteId}
                        onChange={handleChangeSite}
                      >
                        <option disabled={dropDownDisabled}>Select Site</option>
                        {getSitesFromCompanies()}
                      </select>
                    </div>
                  </Form.Group>
                </div>
              </div>
            )}
          </div>
          <div className="col-sm-12 text-end mt-3 w-100">
            <Button type="submit" className="btn btn-event-history">
              Save Changes
            </Button>
          </div>
        </Modal.Body>
      </Form>
    </Modal>
  );
};

export default FormSettingModal;
