import React from "react";
import { IMAGES } from "../../../assets/images/images";

const InfoPassword = () => {
  return (
    <div>
      <p className="text-black">
        Step One: copy the password of your brivo account credentials
      </p>
      <img src={IMAGES.loggedInImage} alt="copy imagee" />
    </div>
  );
};

export default InfoPassword;
