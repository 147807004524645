import React from "react";
import { IMAGES } from "../../../assets/images/images";

const InfoClientSecretKey = () => {
  return (
    <div>
      <p className="text-black">Step One: Copy the client secret</p>
      <img src={IMAGES.invClientSecretCopyImage} alt="" />
    </div>
  );
};

export default InfoClientSecretKey;
