import axios from "axios";
import { useCallback, useState } from "react";
import { decryptData, encryptData } from "../utils";

const useApi = () => {
  const [apiLoading, setApiLoading] = useState(false);
  const [apiError, setApiError] = useState(null);

  const apiCall = useCallback(async (config) => {
    setApiLoading(true);
    setApiError(null);

    try {
      if (config.data.isEncrypted && config.data) {
        const encryptedData = encryptData(config.data);

        config.data = {
          encryptedData: encryptedData,
        };
      }

      const response = await axios(config);
      // Decrypt the response if it is encrypted
      if (response.data && response.data.encryptedData) {
        const decryptedData = decryptData(response.data.encryptedData);
        return decryptedData; // Return the decrypted response
      } else {
        return response; // If not encrypted, return as is
      }

      // return response;
    } catch (err) {
      const errorMessage = err.response?.data?.message || "Network Error";
      setApiError(errorMessage);
      return null; // You can return null or customize this based on how you handle errors.
    } finally {
      setApiLoading(false);
    }
  }, []);

  return { apiCall, apiLoading, apiError };
};

export default useApi;