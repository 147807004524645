import React from "react";
import { AiOutlineUserAdd } from "react-icons/ai";
import classes from "./Index.module.css";

const ButtonUser = ({ handleClick, label, icon, className }) => {
  return (
    <button
      className={`${classes.UserBtn} ${className} btn-lg align-items-center d-flex justify-content-center`}
      onClick={handleClick}
    >
      {icon ? icon : <AiOutlineUserAdd />}
      <span>{label}</span>
    </button>
  );
};

export default ButtonUser;
