import React from "react";
import styles from "./privacyPolicy.module.css";
const PrivacyPolicy = () => {
  return (
    <section className={styles.privacyWrapper}>
      <div className={styles.privacyInner}>
        <h1 className={`${styles.titleHeading} text-center mb-0`}>
          Privacy Policy
        </h1>
        <p>
          <strong className="text-black">
            Sanico USA Mobile Application Privacy Policy
          </strong>
        </p>
        <p>Effective Date: 30/08/2023</p>
        <br />
        <p className="">
          <strong className="mb-2 d-inline-block">Contact Information</strong>{" "}
          <br /> Sanico USA <br /> 245 Hooker Pl. <br /> Staten Island, NY 10303{" "}
          <br />
          New York City, United States <br /> Phone: [(844) 726-4268](tel:(844){" "}
          726-4268)
          <br /> Email: Info@SanicoUSA.com
        </p>
        <br />
        <p>
          <strong>Introduction</strong>
          <p className="mt-2">
            Thank you for choosing to be part of our community at Sanico USA
            ("Company," "we," "us," or "our"). We are committed to safeguarding
            your personal information. This Privacy Policy ("Policy") outlines
            the types of information we collect through our mobile application
            ("App"), how we use it, and the steps we take to protect it.
          </p>
        </p>
        <br />
        <p className="mb-2">
          <strong>Information We Collect Personal Information</strong>
        </p>
        <p>
          We may collect the following categories of personal information <br />{" "}
          Full Name <br /> Email Address <br /> Contact Number <br />{" "}
          Non-Personal Information
        </p>
        <br />
        <p>
          <strong>
            We may also collect non-personal information, which may include but
            is not limited to:
          </strong>
        </p>
        <p className="mt-2">
          Device Type and Operating System <br /> Usage Statistics <br />
          Geolocation Data <br /> Purpose and Legal Basis for Processing
        </p>
        <br />
        <p className="mb-2">
          <strong>
            We use the information we collect for the following purposes:
          </strong>
        </p>
        <p>
          <b>Service Provisioning:</b> To provide, maintain, and improve our
          services. <br />
          <b>Customer Support:</b> To respond to queries, comments, and requests
          for customer support. <br />
          <b>Marketing:</b> To send you promotional materials, subject to your
          opt-in consent. <br />
          <b>Analytics:</b> To analyze user interactions with the App for
          internal purposes.
        </p>
        <br />
        <p className="mb-2">
          <strong>Third-Party Sharing and Disclosure</strong>
        </p>
        <p>
          We will not sell, trade, or transfer your personal information to
          third parties without your explicit consent, except as required by law
          or as specified in this Policy.
        </p>
        <br />
        <p className="mb-2">
          <strong>Data Security Measures</strong>
        </p>
        <p>
          We employ robust security measures, including encryption and
          authentication tools, to protect the security of your personal
          information.
        </p>
        <br />
        <p className="mb-2">
          <strong>Cookies and Tracking Technologies</strong>
        </p>
        <p>
          Our App may use cookies and similar tracking technologies to enhance
          user experience. You have the option to disable cookies through your
          device settings.
        </p>
        <br />
        <p>
          <strong className="">Children's Privacy</strong>
        </p>
        <p className="mt-2">
          Our App is not designed for or directed at children under the age of
          13. We do not knowingly collect information from children under 13.
        </p>
        <br />
        <p className="mb-2">
          <strong>Amendments to This Privacy Policy</strong>
        </p>
        <p>
          We reserve the right to modify this Policy at any time. Any changes
          will be posted on this page, and if significant, will be communicated
          through an in-app notification.
        </p>
        <br />
        <p className="mb-2">
          <strong>Consent</strong>
        </p>
        <p>
          By using our App, you consent to the terms of this Privacy Policy.
        </p>
        <br />
        <p>
          <strong>Contact Us</strong>
        </p>
        <p className="mt-2">
          For any questions or concerns regarding this Privacy Policy, please
          contact us at:
          <br />
          Sanico USA <br />
          245 Hooker Pl. <br />
          Staten Island, NY 10303 <br />
          New York City, United States <br />
          Phone: [(844) 726-4268](tel:(844) 726-4268) <br />
          Email:{" "}
          <a
            style={{ color: "blue" }}
            className="text-decoration-underline"
            href="mailto:Info@SanicoUSA.com"
          >
            Info@SanicoUSA.com
          </a>
        </p>
      </div>
    </section>
  );
};

export default PrivacyPolicy;
