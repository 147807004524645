import axios from "axios";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { FaWpforms } from "react-icons/fa";
import ButtonUser from "../../Components/Common/Button/ButtonUser";
import {
  ADD_NEW_FORM_SETTING,
  DELETE_FORM_SETTING,
  GET_COMPANIES,
  GET_FORM_SETTING_LIST,
  UPDATE_NEW_FORM_SETTING,
} from "../../services/URL";
import Style from "./FormSetting.module.css";
import FormSettingModal from "./FormSettingModal/FormSettingModal";
import classes from "./FormSettingModal/FormSettingModal.module.css";
import FormSettingTable from "./FormSettingTable/FormSettingTable";
import Swal from "sweetalert2";
import Loader from "../../Components/Common/Loader/Loader";

const FormSetting = () => {
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [role, setRole] = useState("");
  const [newRole, setNewRole] = useState({ companyId: "", siteId: "" });
  const [allCompanies, setAllCompanies] = useState([]);
  const [allFormListing, setAllFormListing] = useState([]);
  const [editNewRole, setEditNewRole] = useState({});
  const [dropDownDisabled, setDropDownDisabled] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const bothDropdownsSelected = newRole.companyId && newRole.siteId;
  const [oldEmp, setOldEmp] = useState("");

  const handleEmployeerFormSetting = async (event) => {
    event.preventDefault(); // Prevent the default form submit behavior
    const payload = {
      companyId: newRole.companyId,
      siteId: isEdit ? editNewRole.siteId : newRole.siteId,
      newEmployer: role,
    };
    const payloadForUpdate = {
      companyId: newRole.companyId,
      siteId: isEdit ? editNewRole.siteId : newRole.siteId,
      oldEmployer: oldEmp,
      newEmployer: role,
    };

    try {
      setIsLoading(true);
      let response;
      if (isEdit) {
        response = await axios.post(UPDATE_NEW_FORM_SETTING, payloadForUpdate);
        if (response.data.status === 200) {
          setAllFormListing((prevList) => [response.data.data]);
          setIsLoading(false);
          Swal.fire("Updated!", "The employer has been updated.", "success");
          setIsEdit(false);
        }
        if (response.data.error.length > 0) {
          setIsLoading(false);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `${response.data.error}`,
            showConfirmButton: true,
          });
          return;
        }
      } else {
        const response = await axios.post(ADD_NEW_FORM_SETTING, payload);
        if (response.data.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Added Successfully",
            text: "Employer Added Successfully",
            showConfirmButton: false,
            timer: 1500,
          });
        }
        if (response.data.error.length > 0) {
          setIsLoading(false);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `${response.data.error}`,
            showConfirmButton: true,
          });
          return;
        }

        setIsLoading(false);
        setAllFormListing((prevList) => [response.data.data]);
      }
      setIsShowPopup(false);
      setRole("");
    } catch (error) {
      setIsLoading(false);
      // Handle errors here
      console.error("Error with the API call:", error);
      Swal.fire({
        icon: "error",
        title: "Failed to Add",
        text: "There was a problem adding the new employer. Please try again.",
        showConfirmButton: true,
      });
    }
  };

  const handleClick = () => {
    setIsShowPopup(true);
  };
  const handleInputChange = (e) => {
    setRole(e.target.value);
  };
  useLayoutEffect(() => {
    getAllCompanies();
  }, []);

  const getAllCompanies = async () => {
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      const response = await axios.get(GET_COMPANIES, { headers });
      setAllCompanies(response.data.data);
    } catch (error) {
      console.error(error);
      throw error; // Rethrow the error so that the caller can handle it
    }
  };

  const handleChangeCompanies = (e) => {
    setNewRole({ ...newRole, companyId: e.target.value, siteId: "" });
    setDropDownDisabled(false);
    setAllFormListing("");
  };

  const handleChangeSite = async (e) => {
    setNewRole({ ...newRole, siteId: e.target.value });

    setDropDownDisabled(true);
    const body = {
      siteId: e.target.value,
      companyId: newRole.companyId,
    };

    try {
      setIsLoading(true);
      const response = await axios.post(GET_FORM_SETTING_LIST, body);
      setIsLoading(false);
      setAllFormListing(response.data.data);
    } catch (error) {
      console.error(error);
      throw error; // Rethrow the error so that the caller can handle it
    }
  };

  const getSitesFromCompanies = () => {
    return (
      allCompanies &&
      (
        allCompanies.find((comp) => comp.companyId === newRole.companyId)
          ?.sites || []
      ).map((s) => (
        <option value={s.id} selected={editNewRole.id === s.id} key={s.id}>
          {s.siteName}
        </option>
      ))
    );
  };
  const handleDelete = async (id, roleIndex, employer) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      try {
        setIsLoading(true);
        const response = await axios.delete(DELETE_FORM_SETTING, {
          data: {
            siteId: newRole.siteId,
            companyId: newRole.companyId,
            oldEmployer: employer,
          },
        });

        if (response.data.status === 200 || response.data.status === 204) {
          setAllFormListing((prevList) =>
            prevList.map((formSetting) => {
              if (formSetting._id === id) {
                // Create a new array without the deleted role
                const updatedRoles = formSetting.employerRoles.filter(
                  (_, index) => index !== roleIndex
                );
                return { ...formSetting, employerRoles: updatedRoles };
              }
              return formSetting;
            })
          );
          Swal.fire({
            icon: "success",
            title: "Deleted Successfully",
            text: "Employer Deleted Successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          setIsLoading(false);
        } else {
          setIsLoading(false);
          console.error(
            "Failed to delete the role due to an unexpected status code:",
            response.data.status
          );
          Swal.fire({
            icon: "error",
            title: "Failed to Delete",
            text: "Unexpected status code received.",
          });
        }
      } catch (error) {
        setIsLoading(false);
        console.error("Failed to delete the role:", error);
        Swal.fire({
          icon: "error",
          title: "Failed to Delete",
          text: "There was a problem Deleting the employer. Please try again.",
          showConfirmButton: true,
        });
      }
    } else {
      console.log("Deletion canceled by user.");
    }
  };
  const handleEditForm = (id, index, employer) => {
    setIsEdit(true);
    const formSettingToEdit = allFormListing.find((item) => item._id === id);
    if (formSettingToEdit) {
      setEditNewRole({
        ...editNewRole,
        siteId: formSettingToEdit.siteId,
        companyId: formSettingToEdit.companyId,
        employerRoles: formSettingToEdit.employerRoles,
      });
      setRole(employer);
      setOldEmp(employer);
      setIsShowPopup(true);
    } else {
      console.error("Form setting with the given ID not found.");
    }
  };

  return (
    <div className={Style.userDashboardWrapper}>
      <div className={`${Style.userDashboard} w-100`}>
        <div className={`${Style.leftSide}`}>
          <div>
            <h1>Employer Setting</h1>
          </div>
          <div className="row align-items-end">
            <div className="col-lg-4">
              <Form.Group className={`${classes.formGroupWrapper}`}>
                <label className={`${classes.selectOptionLabel} form-label`}>
                  Companies
                </label>
                <div className={`${classes.customSelectWrapper}`}>
                  <select
                    className={`${classes.customSelect} form-control`}
                    value={newRole.companyId}
                    onChange={handleChangeCompanies}
                  >
                    <option value="" disabled>
                      Select Company
                    </option>
                    {allCompanies &&
                      allCompanies?.map((company) => (
                        <option value={company.companyId} key={company.id}>
                          {company.companyname}
                        </option>
                      ))}
                  </select>
                </div>
              </Form.Group>
            </div>
            <div className={` col-lg-4 `}>
              <Form.Group className={`${classes.formGroupWrapper}`}>
                <label className={`${classes.selectOptionLabel} form-label`}>
                  Sites
                </label>
                <div className={`${classes.customSelectWrapper}`}>
                  <select
                    disabled={!newRole.companyId}
                    className={`${classes.customSelect} form-control`}
                    value={newRole.siteId}
                    onChange={handleChangeSite}
                  >
                    <option disabled={dropDownDisabled}>Select Site</option>
                    {getSitesFromCompanies()}
                  </select>
                </div>
              </Form.Group>
            </div>

            {bothDropdownsSelected && (
              <div className={`col-lg-4`}>
                <ButtonUser
                  handleClick={handleClick}
                  label="Add New Employer"
                  icon={<FaWpforms />}
                />
              </div>
            )}
          </div>
          <FormSettingTable
            formListing={allFormListing}
            handleShowPopup={() => setIsShowPopup(true)}
            handleDelete={handleDelete}
            handleEditForm={handleEditForm}
          />
        </div>
      </div>
      {isShowPopup && (
        <FormSettingModal
          setIsShowPopup={setIsShowPopup}
          handleInputChange={handleInputChange}
          role={role}
          handleEmployeerFormSetting={handleEmployeerFormSetting}
          newRole={newRole}
          allCompanies={allCompanies}
          editNewRole={editNewRole}
          isEdit={isEdit}
          setisEdit={setIsEdit}
          handleChangeSite={handleChangeSite}
          handleChangeCompanies={handleChangeCompanies}
          getSitesFromCompanies={getSitesFromCompanies}
          setRole={setRole}
        />
      )}
      {isLoading && <Loader />}
    </div>
  );
};

export default FormSetting;
