import axios from "axios";
import React, { useState } from "react";
import { FaWpforms } from "react-icons/fa";
import { FaBuildingUser } from "react-icons/fa6";
import { GrLogout } from "react-icons/gr";
import { SiGooglesheets } from "react-icons/si";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { IMAGES } from "../../../assets/images/images";
import { LOGOUT } from "../../../services/URL";
import Loader from "../Loader/Loader";
import classes from "./Index.module.css";

const Navbar = (props) => {
  const userType = useSelector((state) => state.user.userType);
  const dispatch = useDispatch();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // New state to handle sidebar visibility

  const handleToggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const handleSignout = async () => {
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      // setIsLoading(true);
      const payload = {
        id: localStorage.getItem("user"),
      };
      const response = await axios.post(LOGOUT, payload, {
        headers,
      });
      // localStorage.removeItem("users");
      localStorage.removeItem("user");
      localStorage.removeItem("type");
      localStorage.removeItem("companyId");
      localStorage.removeItem("token");
      localStorage.removeItem("siteName");
      localStorage.removeItem("siteId");
      // localStorage.removeItem("persist:root")
      // localStorage.clear();  // Clears all localStorage
      // sessionStorage.clear();
      // setIsLoading(false);

        navigate("/login");

    } catch (error) {
      console.log(error);
    }
  };
  const handleClick = () => {
    userType === "orientation"
      ? navigate("/orientation")
      : navigate("/smartsheets");
  };
  return (
    <div
      className={`${classes.sidebar} ${
        isSidebarOpen ? `${classes.open}` : ""
      } side-bar-edit`}
    >
      {/* Hamburger button */}
      <div className={`${classes.hamburger}`} onClick={handleToggleSidebar}>
        {/* <div className={`${classes.line}`}></div>
        <div className={`${classes.line}`}></div>
        <div className={`${classes.line}`}></div> */}
        <img
          src={IMAGES.hamBurger}
          alt="Ham"
          className={`${classes.hamBurgerIcon}`}
        />
        <img
          src={IMAGES.crossHam}
          alt="Cross"
          className={`${classes.hamCross}`}
        />
      </div>
      <div className={`${classes.logo} cursor-pointer`} onClick={handleClick}>
        <img src={props.logo} alt="Logo" />
      </div>
      <nav className={`${classes.listingWrapper} pt-4`}>
        <ul className="ps-0">
          {props.userRole === "admin" &&  (
            <>
              {/* <li>
                <NavLink
                  exact="true"
                  to="/sites"
                  className={`${classes.activeNavLink} d-flex align-items-center`}
                >
                  <img
                    className="pe-2 dashboard-active-link"
                    src={IMAGES.userlink}
                    alt=""
                  />
                  <img
                    className="pe-2 dashboard-nonactive-link"
                    src={IMAGES.userblack}
                    alt=""
                  />

                  {props.navLink3}
                </NavLink>
              </li> */}

              <li>
                <NavLink
                  exact="true"
                  to="/smartsheets"
                  className={`${classes.activeNavLink} d-flex align-items-center`}
                >
                  <SiGooglesheets fontSize="24px" />
                  <div className="ps-2">{props.navlink6}</div>
                </NavLink>
              </li>
              <li>
                <NavLink
                  exact="true"
                  to="/companies"
                  className={`${classes.activeNavLink} d-flex align-items-center`}
                >
                  <FaBuildingUser fontSize="24px" />
                  <div className="ps-2">{props.navlink5}</div>
                </NavLink>
              </li>
              <li>
                <NavLink
                  exact="true"
                  to="/form-setting"
                  className={`${classes.activeNavLink} d-flex align-items-center`}
                >
                  <FaWpforms fontSize="24px" />
                  <div className="ps-2">{props.navlink7}</div>
                </NavLink>
              </li>
            </>
          ) }
        </ul>
      </nav>

      <div className={`${classes.sideBarFooter} d-flex justify-content-center`}>
        <span
          className={`${classes.signOut} 
             text-green
          `}
          onClick={handleSignout}
        >
          <GrLogout size="24" />
          <span
            className={"ps-1 text-black"}
          >
            {" "}
            Sign Out
          </span>
        </span>
      </div>
      <span
        className={`${classes.presentedByText} text-black `}
      >
        BY SANICO USA
      </span>
      {isLoading && <Loader />}
    </div>
  );
};

export default Navbar;
