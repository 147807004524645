// import React from "react";
// import ReactDOM from "react-dom/client";
// import { Provider } from "react-redux";
// import { BrowserRouter } from "react-router-dom";
// import { PersistGate } from "redux-persist/integration/react";
// import App from "./App";
// import "./index.css";
// import reportWebVitals from "./reportWebVitals";
// import store, { persistor } from "./redux/store";
// import ErrorBoundary from "./Components/Common/Error/ErrorBoundary";
// import * as Sentry from '@sentry/react';

//  Sentry.init({
//   dsn: "https://a16c043355a71d78001d72fb0b65e56d@o4508290665414656.ingest.de.sentry.io/4508290669281360",
//   integrations: [],
// });

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <BrowserRouter>
//         <PersistGate loading={null} persistor={persistor}>
//           <Sentry.ErrorBoundary fallback={<h2>Something went wrong!</h2>}>

//             <App />
//           </Sentry.ErrorBoundary>
          
//         </PersistGate>
//       </BrowserRouter>
//     </Provider>
//   </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();


import * as Sentry from '@sentry/react';
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.css";
import store from "./redux/store";
import reportWebVitals from "./reportWebVitals";

Sentry.init({
  dsn: "https://a16c043355a71d78001d72fb0b65e56d@o4508290665414656.ingest.de.sentry.io/4508290669281360",
  integrations: [],
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Sentry.ErrorBoundary fallback={<h2>Something went wrong!</h2>}>
          <App />
        </Sentry.ErrorBoundary>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
