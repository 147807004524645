import React, { useState } from "react";
import styles from "./ContactUs.module.css";
import logo from "../../assets/images/SafeSitelogo.png";
import Swal from "sweetalert2";

const ContactUs = () => {
  const [username, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    setUserName("");
    setEmail("");
    setPhoneNumber("");
    setMessage("");
    Swal.fire({
      icon: "success",
      text: "Your message has been sent successfully",
      showConfirmButton: false,
      timer: 2000,
    });
  };
  return (
    <div className={styles.containerWrapper}>
      <div className={styles.contactLeftSection}>
        <img src={logo} alt="logo" />
        <p className={styles.presentedByText}>By Sanico USA</p>
      </div>
      <div className={styles.contactUsWrapper}>
        <h1 className={styles.titleHeading}>Contact Us</h1>
        <form
          onSubmit={handleSubmit}
          id="contact"
          className={styles.formWrapper}
        >
          <fieldset>
            <label className="mb-1">Name:</label>
            <input
              placeholder="Your name"
              type="text"
              tabIndex="1"
              required
              name="username"
              value={username}
              onChange={(e) => setUserName(e.target.value)}
              className={`${styles.inputField} form-control`}
            />
          </fieldset>
          <fieldset>
            <label className="mb-1">Email:</label>
            <input
              placeholder="Your Email Address"
              type="email"
              required
              tabIndex="2"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={`${styles.inputField} form-control`}
            />
          </fieldset>
          <fieldset>
            <label className="mb-1">Phone Number:</label>
            <input
              placeholder="Your Phone Number (optional)"
              type="number"
              tabIndex="3"
              name="phoneNumber"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              className={`${styles.inputField} form-control`}
            />
          </fieldset>

          <fieldset>
            <label className="mb-1">Message:</label>
            <textarea
              placeholder="Type your message here...."
              tabIndex="4"
              required
              name="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className={`${styles.inputField} form-control`}
            ></textarea>
          </fieldset>
          <fieldset>
            <button
              className="mt-3"
              name="submit"
              type="submit"
              id="contact-submit"
              data-submit="...Sending"
            >
              Submit
            </button>
          </fieldset>
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
