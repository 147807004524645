import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { IoCheckmarkOutline, IoCopyOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import ButtonUser from "../../Components/Common/Button/ButtonUser";
import InputFields from "../../Components/Common/InputField/InputFields";
import Loader from "../../Components/Common/Loader/Loader";
import SearchBar from "../../Components/Common/Searchbar/SearchBar";
import classes from "../../Components/UserPage/Index.module.css";
import { SANICO_USA_EMPLOYEE_FORM } from "../../Constant/Index";
import {
  ADD_FORM_DETAILS,
  CONNECT_SMARTSHEET,
  GET_COMPANIES,
  UPDATE_SMARTSHEET,
} from "../../services/URL";
import { getCurrentBaseUrl } from "../../utils";

import SmartsheetDataTable from "./SmartsheetDataTable";

const Smartsheets = () => {
  const userType = useSelector((state) => state.user.userType);
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [allCompanies, setAllCompanies] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [connectSmartsheet, setConnectSmartsheet] = useState(false);
  const [generateForm, setGenerateForm] = useState({
    shouldUpdate: false,
    show: false,
  });
  const [newFormDetails, setNewFormDetails] = useState({
    title: "",
    address: "",
  });

  
  const [newSmartsheet, setNewSmartsheet] = useState({
    name: "",
    sheetId: "",
  });

  const [companyId, setCompanyId] = useState("");
  const [siteId, setSiteId] = useState("");
  const [updatedSite, setUpdatedSite] = useState(null);
  const [sites, setSites] = useState([]);
  const [copied, setCopied] = useState(false);


  const handleConnectSmartSheet = (e) => {
    setConnectSmartsheet(true);
    setCompanyId(e.companyId);
    setSiteId(e.siteId);
  };

  const handleGenerateForm = (e) => {
    const shouldUpdate = e.shouldUpdate || false;
    setGenerateForm({ show: true, shouldUpdate });
    if (shouldUpdate && e.formDetails) {
      setNewFormDetails({
        title: e.formDetails.title,
        address: e.formDetails.address,
      });
    }
    setCompanyId(e.companyId);
    setSiteId(e.siteId);
  };

  const getSmartSheetFormLink = (smartSheetData) => {
    const queryParams = new URLSearchParams(smartSheetData).toString();
    return `${getCurrentBaseUrl()}${SANICO_USA_EMPLOYEE_FORM}?${queryParams}`;
  };

  const handleGetSmartSheetForm = (smartSheetData) => {
    const queryParams = new URLSearchParams(smartSheetData).toString();
    navigate(`${SANICO_USA_EMPLOYEE_FORM}?${queryParams}`);
  };

  useEffect(() => {
    let allSites = [];
    allCompanies.forEach((company) => {
      allSites.push(company.sites);
    });
    allSites = allSites.flat(); // Use flat() to flatten the array
    setSites(allSites);
  }, [allCompanies]);

  useEffect(() => {
    if (sites && searchText.length > 0) {
      const filteredResults = sites.filter((item) => {
        const companyName = item.companyName || "";
        const siteName = item.siteName || "";

        return (
          companyName.toLowerCase().includes(searchText.toLowerCase()) ||
          siteName.toLowerCase().includes(searchText.toLowerCase())
        );
      });
      setFilteredResults(filteredResults);
    } else {
      setFilteredResults([]);
    }
  }, [sites, searchText]);

  const handleDisableSmartSheet = async (e) => {
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      setIsLoading(true);

      const response = await axios.put(
        UPDATE_SMARTSHEET,
        {
          companyId: e.companyId,
          siteId: e.siteId,
          sheetId: e.sheetId,
          status: e.status,
        },
        { headers }
      );
      if (response.data.status === 200) {
        setIsLoading(false);
        getCompanies();
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setIsLoading(false);
        console.log("Error 400:", error.response.data);
      } else {
        console.log("Other error:", error);
      }
    }
  };

  const getCompanies = async () => {
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      setIsLoading(true);
      const response = await axios.get(GET_COMPANIES, {
        headers,
        params: { allowNonAppEnabled: true },
      });
      setAllCompanies(response.data.data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  useEffect(() => {
    getCompanies();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewSmartsheet({ ...newSmartsheet, [name]: value });
  };
  const handleChangeforGenerateForm = (e) => {
    const { name, value } = e.target;
    setNewFormDetails({ ...newFormDetails, [name]: value });
  };
  const handleSubmitForGenerateForm = async (e) => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    e.preventDefault();
    try {
      const response = await axios.post(
        ADD_FORM_DETAILS,
        { ...newFormDetails, companyId, siteId },
        {
          headers,
        }
      );
      await getCompanies();
      if (response.data.status === 200) {
        const Result = response.data.message;
        Swal.fire({
          icon: "success",
          title: Result,
          showConfirmButton: false,
          timer: 1500,
        });
        setNewFormDetails({
          title: "",
          address: "",
        });

        setGenerateForm({ show: false, shouldUpdate: false });
      } else {
        setIsLoading(false); // Set loading to false for non-200 status
        // Show Swal error icon for bad response
        Swal.fire({
          icon: "error",
          showConfirmButton: false,
          title: "Wrong Details.",
          timer: 1500,
        });
        // Log the error message to the console
        console.log("Bad Request", response.data.status);
      }
    } catch (error) {
      setIsLoading(false);
      console.log("error", error);
      // Show an error message for any caught error
      Swal.fire({
        icon: "error",
        showConfirmButton: false,
        timer: 1500,
      });
    } finally {
      setIsLoading(false);
    }
  };
  const handleSubmit = async (e) => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    e.preventDefault();
    try {
      const response = await axios.post(
        CONNECT_SMARTSHEET,
        { ...newSmartsheet, companyId, siteId },
        {
          headers,
        }
      );
      await getCompanies();
      if (response.data.status === 200) {
        const updateResult = response.data.data.updateResult.message;
        Swal.fire({
          icon: "success",
          title: updateResult,
          showConfirmButton: false,
          timer: 1500,
        });
        setNewSmartsheet({
          name: "",
          sheetId: "",
        });
        setUpdatedSite({
          siteId,
          enabled: response.data.data.updateResult.data.enabled,
        });
        setConnectSmartsheet(false);
      } else {
        setIsLoading(false); // Set loading to false for non-200 status
        // Show Swal error icon for bad response
        Swal.fire({
          icon: "error",
          showConfirmButton: false,
          title: "Invalid Sheet Id.",
          timer: 1500,
        });
        // Log the error message to the console
        console.log("Bad Request", response.data.status);
      }
    } catch (error) {
      setIsLoading(false);
      console.log("error", error);
      // Show an error message for any caught error
      Swal.fire({
        icon: "error",
        showConfirmButton: false,
        timer: 1500,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const search = (e) => {
    setSearchText(e);
  };

  const resetFormFields = () => {
    setNewSmartsheet({
      name: "",
      sheetId: "",
    });
    setNewFormDetails({
      title: "",
      address: "",
    });
  };

 

  
 

 

  return (
    <div className={classes.userDashboardWrapper}>
      <div className={`${classes.userDashboard} w-100`}>
        <div className={`${classes.leftSide} `}>
          <div>
            <h1>SafeSite USA Onboarding Portal</h1>
          </div>
          <div className="row py-4">
            <div className="col-sm-5 position-relative">
              <SearchBar
                placeholder="Search Jobs"
                search={(e) => search(e)}
              />
            </div>
          </div>
          
            <SmartsheetDataTable
              isShowPopup={isShowPopup}
              setIsShowPopup={setIsShowPopup}
              companies={searchText.length > 0 ? filteredResults : sites}
              updatedSite={updatedSite}
              setUpdatedSite={setUpdatedSite}
              handleConnectSmartSheet={(e) => handleConnectSmartSheet(e)}
              handleDisableSmartSheet={(e) => handleDisableSmartSheet(e)}
              handleGenerateForm={(e) => handleGenerateForm(e)}
              handleGetSmartSheetForm={handleGetSmartSheetForm}
              getSmartSheetFormLink={getSmartSheetFormLink}
            />
          
        </div>
      </div>

      {connectSmartsheet && (
        <Modal
          show={connectSmartsheet}
          onHide={() => {
            setConnectSmartsheet(false);
            resetFormFields();
          }}
          size="md"
        >
          <Modal.Header className="modal-user" closeButton></Modal.Header>
          <Form onSubmit={handleSubmit}>
            <Modal.Body className="p-4">
              <Modal.Title className="text-center">
                Connect Smartsheet
              </Modal.Title>
              <div className="row">
                <div className="col-sm-12 mb-2">
                  <InputFields
                    type="text"
                    inputStyle="modalInput"
                    labelStyle="text-capitalize"
                    label="Name"
                    placeholder="Name"
                    name="name"
                    cssClass="pe-4"
                    value={newSmartsheet.name}
                    handleChange={handleChange}
                  />
                </div>
                <div className="col-sm-12 mb-2">
                  <InputFields
                    type="text"
                    inputStyle="modalInput"
                    labelStyle="text-capitalize"
                    label="Sheet ID"
                    placeholder="Sheet Id"
                    name="sheetId"
                    cssClass="pe-4"
                    value={newSmartsheet.sheetId}
                    handleChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-sm-12 text-end mt-3 w-100">
                <Button type="submit" className="btn btn-sm btn-event-history">
                  Submit
                </Button>
              </div>
            </Modal.Body>
          </Form>
        </Modal>
      )}
      {generateForm.show && (
        <Modal
          show={generateForm.show}
          onHide={() => {
            setGenerateForm({ shouldUpdate: false, show: false });
            resetFormFields();
          }}
          size="md"
        >
          <Modal.Header className="modal-user" closeButton></Modal.Header>
          <Form onSubmit={handleSubmitForGenerateForm}>
            <Modal.Body className="p-4">
              <Modal.Title className="text-center">Generate Form</Modal.Title>
              <div className="row">
                <div className="col-sm-12 mb-2">
                  <InputFields
                    type="text"
                    inputStyle="modalInput"
                    labelStyle="text-capitalize"
                    label="Title"
                    placeholder="Title"
                    name="title"
                    cssClass="pe-4"
                    value={newFormDetails.title}
                    handleChange={handleChangeforGenerateForm}
                  />
                </div>
                <div className="col-sm-12 mb-2">
                  <InputFields
                    type="text"
                    inputStyle="modalInput"
                    labelStyle="text-capitalize"
                    label="Address"
                    placeholder="Address"
                    name="address"
                    cssClass="pe-4"
                    value={newFormDetails.address}
                    handleChange={handleChangeforGenerateForm}
                  />
                </div>
              </div>
              <div className="col-sm-12 text-end mt-3 w-100">
                <Button type="submit" className="btn btn-sm btn-event-history">
                  {generateForm.shouldUpdate ? "Update" : "Submit"}
                </Button>
              </div>
            </Modal.Body>
          </Form>
        </Modal>
      )}

     

      {isLoading && <Loader />}
    </div>
  );
};

export default Smartsheets;
