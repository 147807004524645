import React from "react";
import { IMAGES } from "../../../assets/images/images";

const InfoInvClientSecretKey = () => {
  return (
    <div>
      <p className="text-black">
        Step One: For “invitation client secret” copy the client secret
      </p>
      <img src={IMAGES.invClientSecretStepOne} alt="copy imagee" />
    </div>
  );
};

export default InfoInvClientSecretKey;
