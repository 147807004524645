import { S3Client } from "@aws-sdk/client-s3";
import AWS from "aws-sdk";

const AWS_REGION = "us-east-1"
// const AWS_ACCESS_KEY_ID = "AKIAQOSC6CZ4JZS7CYTT"
// const AWS_SECRET_ACCESS_KEY = "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"

const AWS_ACCESS_KEY_ID = "AKIAQOSC6CZ4AY3MAFKH"
const AWS_SECRET_ACCESS_KEY = "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"



// AWS SDK v2 configuration
AWS.config.update({
  region: AWS_REGION,
  accessKeyId: AWS_ACCESS_KEY_ID,
  secretAccessKey:AWS_SECRET_ACCESS_KEY,
});


// Configure AWS SQS
// export const sqs = new AWS.SQS();

// Configure AWS S3
// export const s3 = new AWS.S3(); // This is valid for AWS SDK v2
export const s3 = new S3Client({
  region: AWS_REGION,
  credentials: {
    accessKeyId: AWS_ACCESS_KEY_ID,
    secretAccessKey: AWS_SECRET_ACCESS_KEY,
  },
});
