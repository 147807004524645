/* eslint-disable no-duplicate-case */
import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import styles from "./InputStyle.module.css";
import PhoneNoInput from "../PhoneNoInput/PhoneNoInput";
import { FaInfoCircle } from "react-icons/fa";

const InputFields = (props) => {
  const {
    label,
    type,
    placeholder,
    labelStyle,
    inputStyle,
    maxlength,
    groupWrapper,
    handleChange,
    value,
    name,
    disabled,
    readonly,
    cssClass,
    handleKeyDown,
    limit,
    required,
    onFocus,
    onBlur,
    checked,
    controlId,
    error, // Add phone prop here
  } = props;

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const getComponent = (type) => {
    switch (type) {
      case "phone":
        return (
          <PhoneNoInput
            handleChange={handleChange}
            value={value || ""}
            disabled={disabled}
            cssClass={`${inputStyle} ${error && "errorField"}`}
            onBlur={onBlur}
            onFocus={onFocus}
          />
        );

      case "checkbox":
        return (
          <Form.Group controlId={controlId}>
            <Form.Check
              type="checkbox"
              name={name}
              label={label}
              checked={checked}
              onChange={handleChange}
            />
          </Form.Group>
        );

      default:
        return (
          <Form.Group
            className={`form-group-wrapper ${groupWrapper} position-relative`}
          >
            {label && (
              <Form.Label className={`${styles.inputLabel} ${labelStyle}`}>
                {label}
                {required ? (
                  <span className="fw-bold fs-6 text-danger"> *</span>
                ) : null}
              </Form.Label>
            )}
            <div className={styles.inputWrapper}>
              <Form.Control
                type={showPassword ? "text" : type}
                readOnly={readonly}
                maxLength={maxlength}
                placeholder={placeholder}
                className={`${styles.inputStyling} ${inputStyle} ${cssClass}`}
                value={value}
                name={name}
                disabled={disabled}
                required
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                maxlength={limit && limit}
                autoComplete="one-time-code" // Corrected attribute name
              />

              {type === "password" && (
                <div
                  className={styles.eyeIcon}
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                </div>
              )}
            </div>
          </Form.Group>
        );
    }
  };

  return <>{getComponent(type)}</>;
};

export default InputFields;
