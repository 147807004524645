import React from "react";
import styles from "./Loader.module.css";
const Loader = () => {
  return (
    <div className={styles.loaderWrapper}>
      <div className={styles.loaderInner}>
        <div className="spinner-border text-success" role="status">
          {/* <span class="sr-only">Loading...</span> */}
        </div>
      </div>
    </div>
  );
};

export default Loader;
