import React, { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { BiCopy } from "react-icons/bi";
import { IMAGES } from "../../../assets/images/images";
import Loader from "../../Common/Loader/Loader";
import classes from "./index.module.css";

const CompanyTable = (props) => {
  const dropdownRef = useRef(null);
  const copyTextRef = useRef(null);
  const [tooltipVisible, setTooltipVisible] = useState(false);

  // Effect to handle outside click to close the dropdown
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveDropdownItemId(null);
        setIsDropdownOpen(false);
      }
    }
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const [activeDropdownItemId, setActiveDropdownItemId] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const {
    handleEdit,
    handleShowEditModal,
    handleShowDeleteModal,
    sites,
    isEdit,
  } = props;
  const [selectedItem] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [skip, setSkip] = useState(10);

  const handleCopyText = () => {
    if (copyTextRef.current) {
      const textToCopy = copyTextRef.current.textContent;
      const textArea = document.createElement("textarea");
      textArea.value = textToCopy;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand("copy");
        setTooltipVisible(true);
        setTimeout(() => {
          setTooltipVisible(false);
        }, 2000);
      } catch (err) {
        console.error("Copy failed: ", err);
      }
      document.body.removeChild(textArea);
    }
  };
  // const getIndex = (index) => {
  //   if (currentPage === 1) {
  //     return index + 1;
  //   } else {
  //     return index + 1 + (currentPage - 1) * skip;
  //   }
  // };

  const columns = [
    // {
    //   name: <b>Id</b>,
    //   selector: (_, index) => getIndex(index),
    //   sortable: false,
    //   width: "50px",
    // },
    {
      name: <b>Project Id</b>,
      selector: (item) => (
        <span className={`${classes.copyIcon} `} title={item.companyId}>
          <div
            className={`d-flex ${classes.breakText} align-items-start ${
              tooltipVisible && `${classes.showToolTip}`
            } `}
          >
            <BiCopy
              title="Copy"
              className="cursor-pointer"
              onClick={handleCopyText}
            />
            <label ref={copyTextRef}>{item.companyId}</label>
          </div>
        </span>
      ),
      sortable: false,
    },
    {
      name: <b>Project Name</b>,
      selector: (item) => item.companyname,
      cell: (item) => (
        <span className={classes.breakText} title={item.companyname}>
          {item.companyname}
        </span>
      ),
      sortable: true,
      //   minWidth: "250px",
    },
    {
      name: <b>User Name</b>,
      selector: (item) => (
        <span className={classes.breakText} title={item.adminUsername}>
          {item.adminUsername}
        </span>
      ),
      // cell: (item) => (
      //   <span className={classes.breakText} title={item.adminUsername}>
      //     {item.adminUsername}
      //   </span>
      // ),
      sortable: true,
    },
    {
      name: <b>Client Id</b>,
      selector: (item) => (
        <span className={classes.breakText}>
          {/* {item.invitationClientId} */}
          <span>***************</span>
        </span>
      ),
      sortable: false,
    },
    {
      name: <b>Client Secret Key</b>,
      selector: (item) => (
        // <span className={classes.breakText} title={item.clientSecret}>
        //   {item.invitationClientSecret}
        // </span>
        <span>***************</span>
      ),
      minWidth: "",
      sortable: false,
    },
    {
      name: <b>Inv Client Id</b>,
      selector: (item) => (
        // <span className={classes.breakText} title={item.invitationClientId}>
        //   {item.invitationClientId}
        // </span>
        <span>***************</span>
      ),
      sortable: false,
    },
    {
      name: <b>Inv Client Secret Key</b>,
      selector: (item) => (
        // <span className={classes.breakText} title={item.invitationClientId}>
        //   {item.invitationClientId}
        // </span>
        <span>***************</span>
      ),
      sortable: false,
    },

    {
      name: <b></b>,
      selector: (item) => {
        const isDropdownActive = activeDropdownItemId === item._id;
        return (
          <>
            <div>
              {isDropdownActive && (
                <div
                  className={`${classes.dropdown} d-flex flex-column`}
                  ref={dropdownRef}
                >
                  <div
                    className={`cursor-pointer ${classes.dropDownItem} `}
                    onClick={() => {
                      handleEdit(item);
                      handleShowEditModal(item._id);
                      isEdit(true);
                    }}
                  >
                    Edit
                  </div>
                  <div
                    className={`cursor-pointer ${classes.dropDownItem}`}
                    onClick={() => handleShowDeleteModal(item._id)}
                  >
                    Delete
                  </div>
                </div>
              )}
            </div>
            <div
              className={`d-flex ${classes.dropdownToggle}`}
              onClick={() => {
                if (isDropdownActive) {
                  setActiveDropdownItemId(null);
                  setIsDropdownOpen(false);
                } else {
                  setActiveDropdownItemId(item._id);
                  setIsDropdownOpen(true);
                }
              }}
            >
              <div
                className={`${
                  isDropdownActive ? classes.kababMenuActive : classes.kababMenu
                }`}
              >
                <img src={IMAGES.kababMenu} alt="" />
              </div>
            </div>
          </>
        );
      },
      width: "50px",
    },
  ];

  const onPageChange = (pageIndex) => {
    setCurrentPage(pageIndex);
  };

  const handlePerRowsChange = (s) => {
    setSkip(s);
  };

  return (
    <div className="data-table-wrapper">
      <div id="userDataTable" className={`${classes.userDataTable}`}>
        <DataTable
          // title="User Data"
          columns={columns}
          data={sites}
          pagination
          highlightOnHover
          // onSort={handleNameSort}
          onChangeRowsPerPage={(s) => handlePerRowsChange(s)}
          onChangePage={(pageNumber) => onPageChange(pageNumber)}
        />
      </div>
      {isLoading && <Loader />}
    </div>
  );
};

export default CompanyTable;
