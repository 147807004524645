import axios from "axios";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { FaBuildingUser } from "react-icons/fa6";
import Swal from "sweetalert2";
import ButtonUser from "../../Components/Common/Button/ButtonUser";
import Loader from "../../Components/Common/Loader/Loader";
import SearchBar from "../../Components/Common/Searchbar/SearchBar";
import AddNewCompanyModal from "../../Components/CompanyPage/AddNewCompanyModal/AddNewCompanyModal";
import CompanyTable from "../../Components/CompanyPage/companyTable/CompanyTable";
import classes from "../../Components/UserPage/Index.module.css";
import { IMAGES } from "../../assets/images/images";
import {
  DELETE_COMPANY,
  GET_COMPANIES,
  REGISTER_COMPANY,
  UPDATE_COMPANY,
} from "../../services/URL";
import { api } from "../../services/auth.serice";
var FormData = require("form-data");

const NEW_USER_OBJ = {
  id: "",
  adminUsername: "",
  companyname: "",
  // ApiKey: "",
  clientId: "",
  invitationClientId: "",
  clientSecret: "",
  invitationClientSecret: "",
  adminPassword: "",
  appEnabled: false,
  biometricEnabled: false,
};

const Companies = () => {
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [isAddNewModal, setIsaddNewModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [sort, setSort] = useState(false);
  const [users, setUsers] = useState([]);
  const [editUser, setEditUser] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [deletedUser, setDeletedUser] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [allSites, setSites] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [addUser, setAddUser] = useState(NEW_USER_OBJ);
  const [isEdit, setIsEdit] = useState(false);
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);

  const handleClick = () => {
    setIsShowPopup(true);
    setIsEdit(false);
    setAddUser(NEW_USER_OBJ);
  };
  const handleClose = () => {
    setIsShowPopup(false);
    setAddUser(NEW_USER_OBJ);
    setFirstNameError("");
    setLastNameError("");
    setEmailError("");
    setPhoneError("");
    setErrorMsg("");
    setEditUser({});
  };

  const search = (e) => {
    setSearchText(e);
  };
  useEffect(() => {
    if (allSites && searchText.length > 0) {
      const filteredResults = allSites.filter(
        (item) =>
          item.companyId.toLowerCase().includes(searchText.toLowerCase()) ||
          item.companyname.toLowerCase().includes(searchText.toLowerCase()) ||
          item.adminUsername.toLowerCase().includes(searchText.toLowerCase()) ||
          item.clientId.toLowerCase().includes(searchText.toLowerCase()) ||
          item.clientSecret.toLowerCase().includes(searchText.toLowerCase()) ||
          item.invitationClientId
            .toLowerCase()
            .includes(searchText.toLowerCase())
      );
      setFilteredResults(filteredResults);
    } else {
      setFilteredResults([]);
      // getCompanies();
    }
  }, [searchText]);

  useEffect(() => {
    getCompanies();
  }, []);

  useEffect(() => {
    if (Object.keys(editUser).length > 0) {
      setEditUser(editUser);
    }
  }, [editUser]);

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      if (!deletedUser) return;
      const response = await axios.delete(DELETE_COMPANY, {
        headers,
        data: { id: deletedUser },
      });
      if (response.data.status === 200) {
        Swal.fire("Project has been deleted successfully");
        if (searchText !== "") {
          search();
        } else {
          getCompanies();
        }
        setShowDeleteModal(false);
      } else {
        Swal.fire({
          icon: "error",
          title: response.data.error[0].message,
          text: response.data.error[0].message,
          showConfirmButton: false,
          timer: 1500,
        });
      }
      setIsLoading(false);
    } catch (error) {
      // Handle error if deletion fails
      Swal.fire("Failed to delete item");
      setIsLoading(false);
    }
  };

  const handleShowDeleteModal = (id) => {
    setDeletedUser(id);
    setShowDeleteModal(true);
  };

  const getCompanies = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(GET_COMPANIES, { headers, params: { allowNonAppEnabled: true } })
      .then((response) => {
        setSites(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const handleShowEditModal = (e) => {
    setIsShowPopup(true);
  };

  const handleSubmit = async (e) => {
    setIsLoading(true);
    setIsDisabled(true);
    e.preventDefault();
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      let response;

      if (!isEdit) {
        response = await axios.post(REGISTER_COMPANY, addUser, { headers });
        if (response.data.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Added Successfully",
            text: "Project Added Successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          setAddUser(NEW_USER_OBJ);
          setIsShowPopup(false);
          setIsDisabled(false);
          setIsLoading(false);
          getCompanies();
        } else {
          Swal.fire({
            icon: "error",
            text: response.data.error[0],
            showConfirmButton: false,
            timer: 1500,
          });
          setIsLoading(false);
        }
      } else {
        // if (addUser.siteId === null) addUser.siteId = editUser.siteId;
        // delete addUser.email;
        var data = new FormData();
        data.append("id", editUser._id);
        data.append("adminUsername", addUser.adminUsername);
        data.append("companyname", addUser.companyname);
        // data.append("ApiKey", addUser.ApiKey);
        data.append("clientId", addUser.clientId);
        data.append("invitationClientId", addUser.invitationClientId);
        data.append("clientSecret", addUser.clientSecret);
        data.append("invitationClientSecret", addUser.invitationClientSecret);
        data.append("adminPassword", addUser.adminPassword);
        data.append("appEnabled", addUser.appEnabled);
        data.append("biometricEnabled", addUser.biometricEnabled);
        // data.append("siteId", addUser.siteId);
        // data.append("type", "site admin");
        var config = {
          method: "post",
          url: UPDATE_COMPANY,
          headers: headers,
          data: data,
        };
        axios(config)
          .then(function (response) {
            if (response.data.status === 200) {
              Swal.fire({
                icon: "success",
                title: "Updated Successfully",
                text: `Admin Updated Successfully`,
                showConfirmButton: false,
                timer: 1500,
              });
              setIsShowPopup(false);
              setAddUser(NEW_USER_OBJ);
              if (searchText !== "") {
                search();
              } else {
                getCompanies();
              }
              setEditUser({});
              setIsLoading(false);
            } else {
              Swal.fire({
                icon: "error",
                text: response.data.error[0],
                showConfirmButton: false,
                timer: 1500,
              });
              setIsLoading(false);
            }
          })
          .catch(function (error) {
            console.log(error);
            setIsLoading(false);
          });
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <div className={classes.userDashboardWrapper}>
      <div className={`${classes.userDashboard} w-100`}>
        <div className={`${classes.leftSide} `}>
          <div>
            <h1>Project On-boarding</h1>
          </div>
          <div className="row py-4">
            <div className="col-sm-5 position-relative">
              <SearchBar
                placeholder="Search Project"
                search={(e) => search(e)}
                handleSort={() => setSort(true)}
              />
            </div>
            <div className="col-lg-3 col-sm-5 col-md-4">
              <ButtonUser
                handleClick={handleClick}
                label="Add New Project"
                icon={<FaBuildingUser />}
              />
            </div>
          </div>
          <CompanyTable
            // users={users}
            handleEdit={(e) => setEditUser(e)}
            editUser={editUser}
            // handleApprove={(id, val) => handleApprove(id, val)}
            handleClose={handleClose}
            handleShowDeleteModal={(e) => handleShowDeleteModal(e)}
            handleShowEditModal={(e) => handleShowEditModal(e)}
            isShowPopup={isShowPopup}
            setIsShowPopup={setIsShowPopup}
            sites={searchText.length > 0 ? filteredResults : allSites}
            isEdit={setIsEdit}
          />
        </div>
      </div>
      {isShowPopup && (
        <AddNewCompanyModal
          handleClose={handleClose}
          setIsaddNewModal={setIsaddNewModal}
          allSites={allSites}
          // users={users}
          setAddUser={setAddUser}
          newUser={addUser}
          handleSubmit={handleSubmit}
          firstNameError={firstNameError}
          lastNameError={lastNameError}
          setLastNameError={setLastNameError}
          setFirstNameError={setFirstNameError}
          phoneError={phoneError}
          setPhoneError={setPhoneError}
          //   emailError={emailError}
          errorMsg={errorMsg}
          setErrorMsg={setErrorMsg}
          setIsShowPopup={setIsShowPopup}
          //   setEmailError={setEmailError}
          editUser={editUser}
          isEdit={isEdit}
        />
      )}

      {showDeleteModal && (
        <div className={classes.deletePopupWrapper}>
          <div className={classes.deletePopupInner}>
            <img src={IMAGES.exclamationIcon} alt="exclamaiton icon" />
            <p>Are you sure you want to delete this Project?</p>
            <p>
              Please note that this will also remove all Security Users and site
              admins linked to it.
            </p>
            <div className={classes.btnWrapper}>
              <button
                className={classes.deleteConfirmButton}
                onClick={() => handleDelete()}
              >
                Yes
              </button>
              <button
                className={classes.deleteCancelButton}
                onClick={() => setShowDeleteModal(false)}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
      {isLoading && <Loader />}
    </div>
  );
};

export default Companies;
